<template>
  <div class="dabox">
    <!-- 文字标识 -->
    <div class="dingwei1">
      <h2>A面</h2>
      <p class="wenzi wenzi1">FIC-B7301</p>
      <p class="wenzi wenzi2">FIC-B7301B</p>
      <p class="wenzi wenzi3">LIT4304B</p>
      <p class="wenzi wenzi4">IIC 7401C</p>
      <p class="wenzi hei wenzi5">TEB7402A</p>
      <p
        class="shuzi shuzi1"
        @click="toCompon(2, 'FT06', 'DB1S', 'FT06_GZQ', '汽提进料流量')"
        @dblclick="Cclick(infoList.DB1S.FT06, 'FT06', 'DB1S', 'FT06_GZQ')"
      >
        {{ infoList.DB1S.FT06 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi2"
        @click="
          toCompon(2, 'FT12', 'DB1S', 'FT12_GZQ', '汽提进料流量2--小板换')
        "
        @dblclick="Cclick(infoList.DB1S.FT12, 'FT12', 'DB1S', 'FT12_GZQ')"
      >
        {{ infoList.DB1S.FT12 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi3"
        @click="toCompon(2, 'LT01', 'DB1S', 'LT01_GZQ', 'TK-2G浆料罐料位')"
        @dblclick="Cclick(infoList.DB1S.LT01, 'LT01', 'DB1S', 'LT01_GZQ')"
      >
        {{ infoList.DB1S.LT01 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi4"
        @click="
          toCompon(
            2,
            'VP03',
            'DB1S',
            'VP03_GZQ',
            '阀门开度--流化床离心机回流阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP03, 'VP03', 'DB1S', 'VP03_GZQ')"
      >
        {{ infoList.DB1S.VP03 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi5"
        @click="
          toCompon(
            2,
            'VP01',
            'DB1S',
            'VP01_GZQ',
            '阀门开度--流化床1#离心机进料阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP01, 'VP01', 'DB1S', 'VP01_GZQ')"
      >
        {{ infoList.DB1S.VP01 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi6"
        @click="
          toCompon(2, 'NI01', 'DB1S', 'NI01_GZQ', '流化床3#离心机进料功率')
        "
        @dblclick="Cclick(infoList.DB1S.NI01, 'NI01', 'DB1S', 'NI01_GZQ')"
      >
        {{ infoList.DB1S.NI01 || "0.00" }} kg
      </p>
      <p
        class="shuzi shuzi7"
        @click="toCompon(2, 'II01', 'DB1S', 'II01_GZQ', '1#离心机进料电流')"
        @dblclick="Cclick(infoList.DB1S.II01, 'II01', 'DB1S', 'II01_GZQ')"
      >
        {{ infoList.DB1S.II01 || "0.00" }} A
      </p>
      <p
        class="shuzi hei shuzi8"
        @click="toCompon(2, 'TE03', 'DB1S', 'TE03_GZQ', '流化床左上部温度')"
        @dblclick="Cclick(infoList.DB1S.TE03, 'TE03', 'DB1S', 'TE03_GZQ')"
      >
        {{ infoList.DB1S.TE03 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi9"
        @click="toCompon(2, 'AN19', 'DB1V', 'AN19_GZQ', '总扭矩')"
        @dblclick="Cclick(infoList.DB1V.AN19, 'AN19', 'DB1V', 'AN19_GZQ')"
      >
        {{ infoList.DB1V.AN19 || "0.00" }}
      </p>
      <p
        class="shuzi shuzi10"
        @click="toCompon(2, 'AN18', 'DB2V', 'AN18_GZQ', '总电流')"
        @dblclick="Cclick(infoList.DB2V.AN18, 'AN18', 'DB2V', 'AN18_GZQ')"
      >
        {{ infoList.DB2V.AN18 || "0.00" }}
      </p>
      <p class="biaoshi biaoshi1">VE-7303B</p>
      <p
        class="caozuo caouo1"
        :style="{
          background:
            infoList.DB1M__p__MAN03 && infoList.DB1M__p__MAN03.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN03
            ? toCompon(0, 'AV', 'DB1M__p__MAN03', 'MAN03_RM_GZQ', '备用')
            : ''
        "
      >
        A
      </p>
      <p
        class="caozuo caouo2"
        :style="{
          background:
            infoList.DB1M__p__MAN01 && infoList.DB1M__p__MAN01.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN01
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN01',
                'MAN01_RM_GZQ',
                '1#离心机进料阀手操器'
              )
            : ''
        "
      >
        A
      </p>
      <p
        class="caozuo caouo3"
        :style="{
          background:
            infoList.DB1M__p__MAN02 && infoList.DB1M__p__MAN02.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN02
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN02',
                'MAN02_RM_GZQ',
                '2#离心机进料阀手操器'
              )
            : ''
        "
      >
        A
      </p>
      <div class="Mdou Mdou1"></div>
      <div class="Mdou Mdou2"></div>
      <div class="Mdou Mdou3"></div>
      <div class="Mdou Mdou4"></div>
      <div class="table">
        <div class="row">
          <div class="cell kuan">一期</div>
          <div class="cell kuan2">
            <span>A</span
            ><span
              class="shou"
              @click="
                toCompon(2, 'AN70', 'DB2V', 'AN70_GZQ', '一期A面下料口温度')
              "
              @dblclick="Cclick(infoList.DB2V.AN70, 'AN70', 'DB2V', 'AN70_GZQ')"
            >
              {{ infoList.DB2V.AN70 || "0.00" }}</span
            >
          </div>
          <div class="cell kuan2">
            <span>B</span>
            <span
            class="shou"
              @click="
                toCompon(2, 'AN71', 'DB2V', 'AN71_GZQ', '一期BA面下料口温度')
              "
              @dblclick="Cclick(infoList.DB2V.AN71, 'AN71', 'DB2V', 'AN71_GZQ')"
            >
              {{ infoList.DB2V.AN71 || "0.00" }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="cell kuan">一期</div>
          <div class="cell kuan2">
            <span>A</span>
            <span
            class="shou"
              @click="
                toCompon(
                  2,
                  'AN67',
                  'DB2V',
                  'AN67_GZQ',
                  '一期A面下料口温度变化率/120S*10'
                )
              "
              @dblclick="Cclick(infoList.DB2V.AN67, 'AN67', 'DB2V', 'AN67_GZQ')"
            >
              {{ infoList.DB2V.AN67 || "0.00" }}</span
            >
          </div>
          <div class="cell kuan2">
            <span>B</span>
            <span
            class="shou"
              @click="
                toCompon(
                  2,
                  'AN68',
                  'DB2V',
                  'AN68_GZQ',
                  '一期B面下料口温度变化率/120S*10'
                )
              "
              @dblclick="Cclick(infoList.DB2V.AN68, 'AN68', 'DB2V', 'AN68_GZQ')"
            >
              {{ infoList.DB2V.AN68 || "0.00" }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="cell kuan">二期</div>
          <div class="cell kuan2">
            <span>A</span>
            <span
            class="shou"
              @click="
                toCompon(
                  2,
                  'AN67',
                  'DB1V',
                  'AN67_GZQ',
                  'A面下料口温度变化率/120S*10'
                )
              "
              @dblclick="Cclick(infoList.DB1V.AN67, 'AN67', 'DB1V', 'AN67_GZQ')"
            >
              {{ infoList.DB1V.AN67 || "0.00" }}</span
            >
          </div>
          <div class="cell kuan2">
            <span>B</span>
            <span
            class="shou"
              @click="
                toCompon(
                  2,
                  'AN68',
                  'DB1V',
                  'AN68_GZQ',
                  'B面下料口温度变化率/120S*10'
                )
              "
              @dblclick="Cclick(infoList.DB1V.AN68, 'AN68', 'DB1V', 'AN68_GZQ')"
            >
              {{ infoList.DB1V.AN68 || "0.00" }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="dingwei2">
      <p class="wenzi wenzi6">IIC 7401D</p>
      <p class="wenzi wenzi7">TI_B7401B</p>
      <p class="wenzi wenzi8">TI_B7402B</p>
      <p class="wenzi wenzi9">PIT B7413</p>
      <p class="wenzi wenzi10">PIT B7408</p>
      <p class="wenzi wenzi11">PIT B7402</p>
      <p class="wenzi wenzi12">AIT B7401B</p>
      <p class="wenzi wenzi13">水分</p>
      <p class="wenzi wenzi14">一期</p>
      <p class="wenzi wenzi15">二期</p>
      <p class="wenzi hei wenzi16">TEB7406A</p>
      <p class="wenzi hei wenzi17">TEB7409A</p>
      <p
        class="shuzi shuzi11"
        @click="
          toCompon(
            2,
            'VP02',
            'DB1S',
            'VP02_GZQ',
            '阀门开度--流化床2#离心机进料阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP02, 'VP02', 'DB1S', 'VP02_GZQ')"
      >
        {{ infoList.DB1S.VP02 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi12"
        @click="
          toCompon(2, 'NI02', 'DB1S', 'NI02_GZQ', '流化床4#离心机进料功率')
        "
        @dblclick="Cclick(infoList.DB1S.NI02, 'NI02', 'DB1S', 'NI02_GZQ')"
      >
        {{ infoList.DB1S.NI02 || "0.00" }} kg
      </p>
      <p
        class="shuzi shuzi13"
        @click="toCompon(2, 'II02', 'DB1S', 'II02_GZQ', '2#离心机进料电流')"
        @dblclick="Cclick(infoList.DB1S.II02, 'II02', 'DB1S', 'II02_GZQ')"
      >
        {{ infoList.DB1S.II02 || "0.00" }} A
      </p>
      <p
        class="shuzi shuzi14"
        @click="toCompon(2, 'VP09', 'DB1S', 'VP09_GZQ', '变频开度--流化床引风')"
        @dblclick="Cclick(infoList.DB1S.VP09, 'VP09', 'DB1S', 'VP09_GZQ')"
      >
        {{ infoList.DB1S.VP09 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi15"
        @click="toCompon(2, 'II04', 'DB1S', 'II04_GZQ', '8旋/床顶引风机电流1')"
        @dblclick="Cclick(infoList.DB1S.II04, 'II04', 'DB1S', 'II04_GZQ')"
      >
        {{ infoList.DB1S.II04 || "0.00" }} A
      </p>
      <p
        class="shuzi shuzi16"
        @click="toCompon(2, 'II05', 'DB1S', 'II05_GZQ', '6旋/床顶引风机电流1')"
        @dblclick="Cclick(infoList.DB1S.II05, 'II05', 'DB1S', 'II05_GZQ')"
      >
        {{ infoList.DB1S.II05 || "0.00" }} A
      </p>
      <p
        class="shuzi shuzi17"
        @click="toCompon(2, 'PT13', 'DB1S', 'PT13_GZQ', '流化床排风风压')"
        @dblclick="Cclick(infoList.DB1S.PT13, 'PT13', 'DB1S', 'PT13_GZQ')"
      >
        {{ infoList.DB1S.PT13 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi18"
        @click="toCompon(2, 'PT10', 'DB1S', 'PT10_GZQ', '流化床床内压力')"
        @dblclick="Cclick(infoList.DB1S.PT10, 'PT10', 'DB1S', 'PT10_GZQ')"
      >
        {{ infoList.DB1S.PT10 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi19"
        @click="
          toCompon(2, 'PT12', 'DB1S', 'PT12_GZQ', '流化床袋滤器/旋分差压')
        "
        @dblclick="Cclick(infoList.DB1S.PT12, 'PT12', 'DB1S', 'PT12_GZQ')"
      >
        {{ infoList.DB1S.PT12 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi20"
        @click="toCompon(2, 'AT01', 'DB1S', 'AT01_GZQ', '产品水分分析值')"
        @dblclick="Cclick(infoList.DB1S.AT01, 'AT01', 'DB1S', 'AT01_GZQ')"
      >
        {{ infoList.DB1S.AT01 || "0.00" }} %
      </p>
      <!-- <p class="shuzi shuzi21" @click="
          toCompon(2, 'AN69', 'DB2V', 'AN69_GZQ', '一期水分值')
        "
        @dblclick="Cclick(infoList.DB2V.AN69, 'AN69', 'DB2V', 'AN69_GZQ')"
      >
        {{ infoList.DB2V.AN69 || "0.00" }}</p> -->
      <p
        class="shuzi shuzi22"
        @click="toCompon(2, 'AN13', 'DB1V', 'AN13_GZQ', '水分实际值防假信号')"
        @dblclick="Cclick(infoList.DB1V.AN13, 'AN13', 'DB1V', 'AN13_GZQ')"
      >
        {{ infoList.DB1V.AN13 || "0.00" }}
      </p>
      <p
        class="shuzi hei shuzi23"
        @click="toCompon(2, 'TE06', 'DB1S', 'TE06_GZQ', '流化床中上部温度')"
        @dblclick="Cclick(infoList.DB1S.TE06, 'TE06', 'DB1S', 'TE06_GZQ')"
      >
        {{ infoList.DB1S.TE06 || "0.00" }} ℃
      </p>
      <p
        class="shuzi hei shuzi24"
        @click="toCompon(2, 'TE09', 'DB1S', 'TE09_GZQ', '流化床右上部温度')"
        @dblclick="Cclick(infoList.DB1S.TE09, 'TE09', 'DB1S', 'TE09_GZQ')"
      >
        {{ infoList.DB1S.TE09 || "0.00" }} ℃
      </p>
      <p class="biaoshi biaoshi2">BL-7403B</p>
      <p class="biaoshi biaoshi3">BL-7402B</p>
      <p class="biaoshi biaoshi4">包装</p>
      <p class="biaoshi biaoshi5">大气</p>
      <p class="biaoshi biaoshi6">C</p>
      <p
        class="caozuo caouo4"
        :style="{
          background:
            infoList.DB1M__p__MAN09 && infoList.DB1M__p__MAN09.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN09
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN09',
                'MAN09_RM_GZQ',
                '活塞风温手操器'
              )
            : ''
        "
      >
        A
      </p>
    </div>
    <div class="dingwei3">
      <p class="wenzi wenzi18">FIT_B7401</p>
      <p class="wenzi wenzi19">TICB7401A</p>
      <p class="wenzi wenzi20">PITB7401A</p>
      <p class="wenzi wenzi21">HCV7402A</p>
      <p class="wenzi hei wenzi22">PITB7404</p>
      <p class="wenzi hei wenzi23">TEB7403A</p>
      <p class="wenzi hei wenzi24">PIT7403B</p>
      <p class="wenzi hei wenzi25">TEB7404A</p>
      <p class="wenzi hei wenzi26">PITB7405</p>
      <p class="wenzi wenzi27">TICB7405</p>
      <p class="wenzi wenzi28">PITB7415</p>
      <p class="wenzi wenzi29">IT_B7403B</p>
      <p class="wenzi wenzi30">STL</p>
      <p class="wenzi wenzi31">STL</p>
      <p class="wenzi wenzi32">B下料口温度设定值:</p>
      <p class="wenzi wenzi33">蒸汽温度高限: 禁加蒸汽阀</p>
      <p class="wenzi wenzi34">CL</p>
      <p
        class="shuzi shuzi25"
        @click="
          toCompon(2, 'FT04', 'DB1S', 'FT04_GZQ', '流化床前段蒸汽/循环热水流量')
        "
        @dblclick="Cclick(infoList.DB1S.FT04, 'FT04', 'DB1S', 'FT04_GZQ')"
      >
        {{ infoList.DB1S.FT04 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi26"
        @click="toCompon(2, 'TE17', 'DB1S', 'TE17_GZQ', '流化床进床蒸汽温度')"
        @dblclick="Cclick(infoList.DB1S.TE17, 'TE17', 'DB1S', 'TE17_GZQ')"
      >
        {{ infoList.DB1S.TE17 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi27"
        @click="toCompon(2, 'PT02', 'DB1S', 'PT02_GZQ', '流化床进床蒸汽压力')"
        @dblclick="Cclick(infoList.DB1S.PT02, 'PT02', 'DB1S', 'PT02_GZQ')"
      >
        {{ infoList.DB1S.PT02 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi28"
        @click="
          toCompon(2, 'VP14', 'DB1S', 'VP14_GZQ', '阀门开度--蒸汽减温水阀门')
        "
        @dblclick="Cclick(infoList.DB1S.VP14, 'VP14', 'DB1S', 'VP14_GZQ')"
      >
        {{ infoList.DB1S.VP14 || "0.00" }} %
      </p>
      <p
        class="shuzi hei shuzi29"
        @click="
          toCompon(2, 'PT07', 'DB1S', 'PT07_GZQ', '流化床前段料床差压/室压')
        "
        @dblclick="Cclick(infoList.DB1S.PT07, 'PT07', 'DB1S', 'PT07_GZQ')"
      >
        {{ infoList.DB1S.PT07 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi hei shuzi30"
        @click="toCompon(2, 'TE04', 'DB1S', 'TE04_GZQ', '流化床左中部部温度')"
        @dblclick="Cclick(infoList.DB1S.TE04, 'TE04', 'DB1S', 'TE04_GZQ')"
      >
        {{ infoList.DB1S.TE04 || "0.00" }} ℃
      </p>
      <p
        class="shuzi hei shuzi31"
        @click="toCompon(2, 'PT18', 'DB1S', 'PT18_GZQ', '流化床下料器下部压力')"
        @dblclick="Cclick(infoList.DB1S.PT18, 'PT18', 'DB1S', 'PT18_GZQ')"
      >
        {{ infoList.DB1S.PT18 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi hei shuzi32"
        @click="toCompon(2, 'TE05', 'DB1S', 'TE05_GZQ', '流化床左下部部温度')"
        @dblclick="Cclick(infoList.DB1S.TE05, 'TE05', 'DB1S', 'TE05_GZQ')"
      >
        {{ infoList.DB1S.TE05 || "0.00" }} ℃
      </p>
      <p
        class="shuzi hei shuzi33"
        @click="toCompon(2, 'PT04', 'DB1S', 'PT04_GZQ', '流化床前段风室风压')"
        @dblclick="Cclick(infoList.DB1S.PT04, 'PT04', 'DB1S', 'PT04_GZQ')"
      >
        {{ infoList.DB1S.PT04 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi34"
        @click="toCompon(2, 'TE13', 'DB1S', 'TE13_GZQ', '流化床进床热风温度1')"
        @dblclick="Cclick(infoList.DB1S.TE13, 'TE13', 'DB1S', 'TE13_GZQ')"
      >
        {{ infoList.DB1S.TE13 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi35"
        @click="toCompon(2, 'PT03', 'DB1S', 'PT03_GZQ', '流化床混料段进风风压')"
        @dblclick="Cclick(infoList.DB1S.PT03, 'PT03', 'DB1S', 'PT03_GZQ')"
      >
        {{ infoList.DB1S.PT03 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi36"
        @click="toCompon(2, 'II03', 'DB1S', 'II03_GZQ', '鼓风机电流')"
        @dblclick="Cclick(infoList.DB1S.II03, 'II03', 'DB1S', 'II03_GZQ')"
      >
        {{ infoList.DB1S.II03 || "0.00" }} A
      </p>
      <p
        class="shuzi shuzi37"
        @click="
          toCompon(
            2,
            'VP04',
            'DB1S',
            'VP04_GZQ',
            '阀门开度--流化床蒸汽温度/压力调节阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP04, 'VP04', 'DB1S', 'VP04_GZQ')"
      >
        {{ infoList.DB1S.VP04 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi38"
        @click="
          toCompon(
            2,
            'VP06',
            'DB1S',
            'VP06_GZQ',
            '阀门开度--流化床进床热风温度调节阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP06, 'VP06', 'DB1S', 'VP06_GZQ')"
      >
        {{ infoList.DB1S.VP06 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi39"
        @click="toCompon(2, 'TE19', 'DB1S', 'TE19_GZQ', '一床热风温度实值')"
        @dblclick="Cclick(infoList.DB1S.TE19, 'TE19', 'DB1S', 'TE19_GZQ')"
      >
        {{ infoList.DB1S.TE19 || "0.00" }}
      </p>
      <p
        class="shuzi shuzi40"
        @click="toCompon(2, 'VP08', 'DB1S', 'VP08_GZQ', '变频开度--流化床鼓风')"
        @dblclick="Cclick(infoList.DB1S.VP08, 'VP08', 'DB1S', 'VP08_GZQ')"
      >
        {{ infoList.DB1S.VP08 || "0.00" }} %
      </p>
      <p
        class="shuzi fen shuzi41"
        @click="toCompon(2, 'SP19', 'DB1S', 'SP19_GZQ', '备用设定值19')"
        @dblclick="Cclick(infoList.DB1S.SP19, 'SP19', 'DB1S', 'SP19_GZQ')"
      >
        {{ infoList.DB1S.SP19 || "0.00" }}
      </p>
      <p
        class="shuzi fen shuzi42"
        @click="toCompon(2, 'AN48', 'DB1V', 'AN48_GZQ', '模拟量中间变量4')"
        @dblclick="Cclick(infoList.DB1V.AN48, 'AN48', 'DB1V', 'AN48_GZQ')"
      >
        {{ infoList.DB1V.AN48 || "0.00" }}
      </p>
      <p class="biaoshi biaoshi7">空气</p>
      <p class="biaoshi biaoshi8">过滤器</p>
      <p class="biaoshi biaoshi9">BL-7401B</p>
      <p class="biaoshi biaoshi10">HE-7402</p>
      <p
        class="caozuo caouo5"
        :style="{
          background:
            infoList.DB1M__p__MAN04 && infoList.DB1M__p__MAN04.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN04
            ? toCompon(0, 'AV', 'DB1M__p__MAN04', 'MAN04_RM_GZQ', '备用')
            : ''
        "
      >
        A
      </p>
      <p
        class="caozuo caouo6"
        :style="{
          background:
            infoList.DB1M__p__MAN14 && infoList.DB1M__p__MAN14.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN14
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN14',
                'MAN14_RM_GZQ',
                '三通阀手操器'
              )
            : ''
        "
      >
        A
      </p>
      <p
        class="caozuo caouo7"
        :style="{
          background:
            infoList.DB1M__p__MAN06 && infoList.DB1M__p__MAN06.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN06
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN06',
                'MAN06_RM_GZQ',
                '混料风量手操器'
              )
            : ''
        "
      >
        A
      </p>
      <p
        class="caozuo caouo8"
        :style="{
          background:
            infoList.DB1M__p__MAN08 && infoList.DB1M__p__MAN08.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN08
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN08',
                'MAN08_RM_GZQ',
                '活塞风量手操器'
              )
            : ''
        "
      >
        A
      </p>
    </div>
    <div class="dingwei4">
      <p class="wenzi hei wenzi35">PITB7406</p>
      <p class="wenzi hei wenzi36">TEB7407A</p>
      <p class="wenzi hei wenzi37">TEB7410A</p>
      <p class="wenzi hei wenzi38">PITB7409</p>
      <p class="wenzi hei wenzi39">TEB7408A</p>
      <p class="wenzi hei wenzi40">TEB7411A</p>
      <p class="wenzi hei wenzi41">TEB7412A</p>
      <p class="wenzi hei wenzi42">PITB7410</p>
      <p class="wenzi hei wenzi43">PITB7407</p>
      <p class="wenzi wenzi44">HCVB7403A</p>
      <p class="wenzi wenzi45">TE_B7417</p>
      <p class="wenzi wenzi46">FIT_B7409</p>
      <p class="wenzi wenzi47">LITB7403</p>
      <p class="wenzi wenzi61">LITB7403</p>
      <p class="wenzi wenzi48">TICB7414</p>
      <p class="wenzi wenzi49">TICB7414</p>
      <p class="wenzi wenzi50">PIC_S7405C</p>
      <p class="wenzi wenzi51">PITB7411</p>
      <!-- <p class="wenzi wenzi52">PI-0710</p> -->
      <p class="wenzi wenzi53">R/MIN</p>
      <p class="wenzi wenzi54">IT_B7404C</p>
      <p class="wenzi wenzi55">LITB7401</p>
      <p class="wenzi wenzi56">LITB7404</p>
      <p class="wenzi wenzi57">LICB7404.OP</p>
      <p class="wenzi wenzi58">TIT_B7410A</p>
      <p class="wenzi wenzi59">风送压力高限: 禁加</p>
      <p class="wenzi wenzi60">STL</p>
      <p
        class="shuzi hei shuzi43"
        @click="
          toCompon(2, 'PT08', 'DB1S', 'PT08_GZQ', '流化床中段料床差压/室压')
        "
        @dblclick="Cclick(infoList.DB1S.PT08, 'PT08', 'DB1S', 'PT08_GZQ')"
      >
        {{ infoList.DB1S.PT08 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi hei shuzi44"
        @click="toCompon(2, 'TE07', 'DB1S', 'TE07_GZQ', '流化床中中部部温度')"
        @dblclick="Cclick(infoList.DB1S.TE07, 'TE07', 'DB1S', 'TE07_GZQ')"
      >
        {{ infoList.DB1S.TE07 || "0.00" }} ℃
      </p>
      <p
        class="shuzi hei shuzi45"
        @click="toCompon(2, 'TE10', 'DB1S', 'TE10_GZQ', '流化床右中部部温度')"
        @dblclick="Cclick(infoList.DB1S.TE10, 'TE10', 'DB1S', 'TE10_GZQ')"
      >
        {{ infoList.DB1S.TE10 || "0.00" }} ℃
      </p>
      <p
        class="shuzi hei shuzi46"
        @click="
          toCompon(2, 'PT09', 'DB1S', 'PT09_GZQ', '流化床后段料床差压/室压')
        "
        @dblclick="Cclick(infoList.DB1S.PT09, 'PT09', 'DB1S', 'PT09_GZQ')"
      >
        {{ infoList.DB1S.PT09 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi hei shuzi47"
        @click="toCompon(2, 'TE08', 'DB1S', 'TE08_GZQ', '流化床中下部部温度')"
        @dblclick="Cclick(infoList.DB1S.TE08, 'TE08', 'DB1S', 'TE08_GZQ')"
      >
        {{ infoList.DB1S.TE08 || "0.00" }} ℃
      </p>
      <p
        class="shuzi hei shuzi48"
        @click="toCompon(2, 'TE11', 'DB1S', 'TE11_GZQ', '流化床右下部部温度')"
        @dblclick="Cclick(infoList.DB1S.TE11, 'TE11', 'DB1S', 'TE11_GZQ')"
      >
        {{ infoList.DB1S.TE11 || "0.00" }} ℃
      </p>
      <p
        class="shuzi hei shuzi49"
        @click="toCompon(2, 'TE14', 'DB1S', 'TE14_GZQ', '流化床进床热风温度2')"
        @dblclick="Cclick(infoList.DB1S.TE14, 'TE14', 'DB1S', 'TE14_GZQ')"
      >
        {{ infoList.DB1S.TE14 || "0.00" }} ℃
      </p>
      <p
        class="shuzi hei shuzi50"
        @click="toCompon(2, 'PT06', 'DB1S', 'PT06_GZQ', '流化床后段风室风压')"
        @dblclick="Cclick(infoList.DB1S.PT06, 'PT06', 'DB1S', 'PT06_GZQ')"
      >
        {{ infoList.DB1S.PT06 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi hei shuzi51"
        @click="toCompon(2, 'PT05', 'DB1S', 'PT05_GZQ', '流化床中段风室风压')"
        @dblclick="Cclick(infoList.DB1S.PT05, 'PT05', 'DB1S', 'PT05_GZQ')"
      >
        {{ infoList.DB1S.PT05 || "0.00" }} Kpa
      </p>
      <p
        class="shuzi shuzi52"
        @click="
          toCompon(2, 'VP11', 'DB1S', 'VP11_GZQ', '阀门开度--流化床旋转下料器')
        "
        @dblclick="Cclick(infoList.DB1S.VP11, 'VP11', 'DB1S', 'VP11_GZQ')"
      >
        {{ infoList.DB1S.VP11 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi53"
        @click="toCompon(2, 'TE18', 'DB1S', 'TE18_GZQ', '流化床热水进水温度')"
        @dblclick="Cclick(infoList.DB1S.TE18, 'TE18', 'DB1S', 'TE18_GZQ')"
      >
        {{ infoList.DB1S.TE18 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi54"
        @click="
          toCompon(2, 'FT05', 'DB1S', 'FT05_GZQ', '流化床后段蒸汽/循环热水流量')
        "
        @dblclick="Cclick(infoList.DB1S.FT05, 'FT05', 'DB1S', 'FT05_GZQ')"
      >
        {{ infoList.DB1S.FT05 || "0.00" }} m³/h
      </p>
      <p
        class="shuzi shuzi55"
        @click="toCompon(2, 'LT03', 'DB1S', 'LT03_GZQ', '流化床补水罐液位')"
        @dblclick="Cclick(infoList.DB1S.LT03, 'LT03', 'DB1S', 'LT03_GZQ')"
      >
        {{ infoList.DB1S.LT03 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi69"
        @click="
          toCompon(2, 'VP12', 'DB1S', 'VP12_GZQ', '阀门开度--流化床热水补充')
        "
        @dblclick="Cclick(infoList.DB1S.VP12, 'VP12', 'DB1S', 'VP12_GZQ')"
      >
        {{ infoList.DB1S.VP12 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi56"
        @click="toCompon(2, 'TE16', 'DB1S', 'TE16_GZQ', '流化床热水温度')"
        @dblclick="Cclick(infoList.DB1S.TE16, 'TE16', 'DB1S', 'TE16_GZQ')"
      >
        {{ infoList.DB1S.TE16 || "0.00" }} ℃
      </p>
      <p
        class="shuzi shuzi57"
        @click="
          toCompon(
            2,
            'VP05',
            'DB1S',
            'VP05_GZQ',
            '阀门开度--流化床热水温度调节阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP05, 'VP05', 'DB1S', 'VP05_GZQ')"
      >
        {{ infoList.DB1S.VP05 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi58"
        @click="
          toCompon(2, 'VP16', 'DB1S', 'VP16_GZQ', '变频器开度-一次输送下料器A')
        "
        @dblclick="Cclick(infoList.DB1S.VP16, 'VP16', 'DB1S', 'VP16_GZQ')"
      >
        {{ infoList.DB1S.VP16 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi59"
        @click="toCompon(2, 'PT15', 'DB1S', 'PT15_GZQ', '流化床风送压力')"
        @dblclick="Cclick(infoList.DB1S.PT15, 'PT15', 'DB1S', 'PT15_GZQ')"
      >
        {{ infoList.DB1S.PT15 || "0.00" }} Kpa
      </p>
      <!-- <p class="shuzi shuzi60">-3.70 Kpa</p> -->
      <p
        class="shuzi shuzi61"
        @click="toCompon(2, 'II08', 'DB1S', 'II08_GZQ', '下料器转速')"
        @dblclick="Cclick(infoList.DB1S.II08, 'II08', 'DB1S', 'II08_GZQ')"
      >
        {{ infoList.DB1S.II08 || "0.00" }}
      </p>
      <p
        class="shuzi shuzi62"
        @click="toCompon(2, 'II06', 'DB1S', 'II06_GZQ', '罗茨风机电流1')"
        @dblclick="Cclick(infoList.DB1S.II06, 'II06', 'DB1S', 'II06_GZQ')"
      >
        {{ infoList.DB1S.II06 || "0.00" }} A
      </p>
      <p
        class="shuzi shuzi63"
        @click="toCompon(2, 'LT04', 'DB1S', 'LT04_GZQ', '流化床蒸汽冷凝水液位')"
        @dblclick="Cclick(infoList.DB1S.LT04, 'LT04', 'DB1S', 'LT04_GZQ')"
      >
        {{ infoList.DB1S.LT04 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi64"
        @click="toCompon(2, 'LT05', 'DB1S', 'LT05_GZQ', '流化床离心母液水液位')"
        @dblclick="Cclick(infoList.DB1S.LT05, 'LT05', 'DB1S', 'LT05_GZQ')"
      >
        {{ infoList.DB1S.LT05 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi65"
        @click="
          toCompon(2, 'VP17', 'DB1S', 'VP17_GZQ', '阀门开度-热水罐至离心母液水')
        "
        @dblclick="Cclick(infoList.DB1S.VP17, 'VP17', 'DB1S', 'VP17_GZQ')"
      >
        {{ infoList.DB1S.VP17 || "0.00" }} %
      </p>
      <p
        class="shuzi shuzi66"
        @click="
          toCompon(
            2,
            'VP13',
            'DB1S',
            'VP13_GZQ',
            '阀门开度--流化床热水流量调节'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP13, 'VP13', 'DB1S', 'VP13_GZQ')"
      >
        {{ infoList.DB1S.VP13 || "0.00" }} %
      </p>
      <p
        class="shuzi fen shuzi67"
        @click="toCompon(2, 'AN47', 'DB1V', 'AN47_GZQ', '模拟量中间变量3')"
        @dblclick="Cclick(infoList.DB1V.AN47, 'AN47', 'DB1V', 'AN47_GZQ')"
      >
        {{ infoList.DB1V.AN47 || "0.00" }}
      </p>
      <p
        class="shuzi hei shuzi68"
        @click="
          toCompon(
            2,
            'VP15',
            'DB1S',
            'VP15_GZQ',
            '阀门开度-流化床3/4段蒸汽补偿阀'
          )
        "
        @dblclick="Cclick(infoList.DB1S.VP15, 'VP15', 'DB1S', 'VP15_GZQ')"
      >
        {{ infoList.DB1S.VP15 || "0.00" }} %
      </p>
      <p class="biaoshi hei biaoshi11">B</p>
      <p class="biaoshi hei biaoshi12">A</p>
      <p class="biaoshi biaoshi13">A</p>
      <p class="biaoshi biaoshi14">B</p>
      <p class="biaoshi biaoshi15">HW</p>
      <p class="biaoshi biaoshi16">C</p>
      <p class="biaoshi biaoshi17">6旋</p>
      <p class="biaoshi biaoshi18">罗茨风机</p>
      <p
        class="caozuo caouo9"
        :style="{
          background:
            infoList.DB1M__p__MAN13 && infoList.DB1M__p__MAN13.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN13
            ? toCompon(
                0,
                'AV',
                'DB1M__p__MAN13',
                'MAN13_RM_GZQ',
                '床内负压手操器'
              )
            : ''
        "
      >
        A
      </p>
      <p
        class="caozuo caouo10"
        :style="{
          background:
            infoList.DB1M__p__MAN15 && infoList.DB1M__p__MAN15.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN15
            ? toCompon(0, 'AV', 'DB1M__p__MAN15', 'MAN15_RM_GZQ', '备用1')
            : ''
        "
      >
        A
      </p>
      <p
        class="caozuo caouo11"
        :style="{
          background:
            infoList.DB1M__p__MAN12 && infoList.DB1M__p__MAN12.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN12
            ? toCompon(0, 'AV', 'DB1M__p__MAN12', 'MAN12_RM_GZQ', '备用')
            : ''
        "
      >
        A
      </p>
      <p
        class="caozuo caouo12"
        :style="{
          background:
            infoList.DB1M__p__MAN17 && infoList.DB1M__p__MAN17.RM == 1
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList.DB1M__p__MAN17
            ? toCompon(0, 'AV', 'DB1M__p__MAN17', 'MAN17_RM_GZQ', '备用3')
            : ''
        "
      >
        A
      </p>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "Gzjlt",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  // filters: {
  //   // 阀门红绿灯
  //   typeFifter: (val) => {
  //     if (val < 5) {
  //       return require("@/assets/images/截止阀.png");
  //     } else if (val >= 5) {
  //       return require("@/assets/images/截止阀2.png");
  //     }
  //   },
  //   //釜内红绿灯
  //   tyFifter: (val) => {
  //     if (val <= 10) {
  //       return require("@/assets/images/关闭.png");
  //     } else if (val > 10) {
  //       return require("@/assets/images/开始.png");
  //     }
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.dabox {
  box-sizing: border-box;
  width: 90%;
  height: 81vh;
  margin-left: 5vw;
  margin-top: 2vh;
  background-image: url("~@/assets/images/干燥流化床.png");
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  .dingwei1,
  .dingwei2,
  .dingwei3,
  .dingwei4 {
    position: relative;
    font-size: 0.8vw;
    width: 50%;
    height: 50%;
    color: #fff;
    // border: 1px solid #fff;
    .hei {
      color: #000;
    }
    h2 {
      position: absolute;
      top: -0.5vw;
      right: 0vw;
    }
    .wenzi {
      position: absolute;
      // color: #000;
    }
    .shuzi {
      position: absolute;
      font-size: 0.7vw;
      z-index: 20;
      cursor: pointer;
    }
    .shou {
      cursor: pointer;
    }
    .fen {
      color: #ff09db;
    }
    .biaoshi {
      position: absolute;
      font-weight: 700;
      font-size: 1vw;
    }
    .caozuo {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #2afc30;
      color: #fff;
      width: 1vw;
      height: 2vh;
      box-sizing: border-box;
      border: 1px solid #fdf061;
      font-size: 0.8vw;
      cursor: pointer;
      z-index: 11; /* 提高层级 */
    }
    .Mdou {
      position: absolute;
      width: 1.5vw;
      height: 3vh;
      background-image: url("~@/assets/images/M.png");
      background-size: 100% 100%;
    }

    .table {
      position: absolute;
      bottom: 0vh;

      display: flex;
      flex-direction: column;
      width: 15.5vw; /* Set the width of the table */
      // border: 1px solid #024652;
    }
    .row {
      display: flex;
      flex: 1;
    }
    .cell {
      border: 0.1vw solid#024652;
      box-sizing: border-box;
    }
    .kuan {
      width: 3vw;
      text-align: center;
    }
    .kuan2 {
      width: 6.3vw;
      padding-left: 1vw;
      padding-right: 1vw;
      display: flex;
      justify-content: space-between;
    }
    .wenzi1 {
      top: 10vh;
      left: 0vw;
    }
    .wenzi2 {
      top: 17vh;
      left: 0vw;
    }
    .wenzi3 {
      top: 19vh;
      left: 11.5vw;
    }
    .wenzi4 {
      top: 5vh;
      left: 28vw;
    }
    .wenzi5 {
      bottom: 0.5vh;
      left: 42.2vw;
    }
    .shuzi1 {
      top: 12vh;
      left: 0vw;
    }
    .shuzi2 {
      top: 19vh;
      left: 0vw;
    }
    .shuzi3 {
      top: 21vh;
      left: 11.5vw;
    }
    .shuzi4 {
      top: 7vh;
      left: 9.5vw;
    }
    .shuzi5 {
      top: 7vh;
      left: 28vw;
    }
    .shuzi6 {
      top: 9.5vh;
      left: 28vw;
    }
    .shuzi7 {
      top: 11.5vh;
      left: 28vw;
    }
    .shuzi8 {
      bottom: -1vh;
      left: 42.2vw;
    }
    .shuzi9 {
      top: 25vh;
      left: 13.5vw;
    }
    .shuzi10 {
      top: 27vh;
      left: 13.5vw;
    }
    .biaoshi1 {
      top: 23vh;
      left: 5.8vw;
    }
    .caouo1 {
      top: 4vh;
      left: 11.5vw;
    }
    .caouo2 {
      top: 7.7vh;
      left: 22vw;
    }
    .caouo3 {
      top: 7.7vh;
      right: 3.5vw;
    }
    .Mdou1 {
      bottom: 9vh;
      right: 23vw;
    }
    .Mdou2 {
      bottom: 9vh;
      right: 17.5vw;
    }
    .Mdou3 {
      bottom: 9vh;
      right: 4.5vw;
    }
    .Mdou4 {
      bottom: 9vh;
      right: -1vw;
    }
    .wenzi6 {
      top: 5vh;
      left: 1.5vw;
    }
    .wenzi7 {
      top: 8vh;
      left: 25.5vw;
    }
    .wenzi8 {
      top: 13vh;
      left: 37.5vw;
    }
    .wenzi9 {
      top: 17vh;
      left: 29vw;
    }
    .wenzi10 {
      bottom: 8vh;
      left: 7.8vw;
    }
    .wenzi11 {
      bottom: 8vh;
      left: 15.8vw;
    }
    .wenzi12 {
      bottom: 7.5vh;
      left: 28.8vw;
    }
    .wenzi13 {
      bottom: 10vh;
      left: 30vw;
    }

    .wenzi14 {
      bottom: 10vh;
      right: 0.5vw;
    }
    .wenzi15 {
      bottom: 6vh;
      right: 0.5vw;
    }
    .wenzi16 {
      bottom: 0.5vh;
      left: 8vw;
    }
    .wenzi17 {
      bottom: 0.5vh;
      left: 13vw;
    }
    .shuzi11 {
      top: 7vh;
      left: 1.5vw;
    }
    .shuzi12 {
      top: 10vh;
      left: 1.5vw;
    }
    .shuzi13 {
      top: 12vh;
      left: 1.5vw;
    }
    .shuzi14 {
      top: 5vh;
      left: 25.5vw;
    }
    .shuzi15 {
      top: 10.5vh;
      left: 25.5vw;
    }
    .shuzi16 {
      top: 15.5vh;
      left: 37.5vw;
    }
    .shuzi17 {
      top: 19.3vh;
      left: 29vw;
    }
    .shuzi18 {
      bottom: 6vh;
      left: 7.8vw;
    }
    .shuzi19 {
      bottom: 6vh;
      left: 15.8vw;
    }
    .shuzi20 {
      bottom: 5vh;
      left: 28.8vw;
    }

    .shuzi21 {
      bottom: 10vh;
      right: 3vw;
    }
    .shuzi22 {
      bottom: 6vh;
      right: 3vw;
    }
    .shuzi23 {
      bottom: -1vh;
      left: 8vw;
    }
    .shuzi24 {
      bottom: -1vh;
      left: 13vw;
    }
    .biaoshi2 {
      top: 13vh;
      left: 20.3vw;
    }
    .biaoshi3 {
      top: 24vh;
      right: 7.5vw;
    }
    .biaoshi4 {
      bottom: 7.5vh;
      right: 5.5vw;
    }
    .biaoshi5 {
      top: 3.5vh;
      right: 0.5vw;
    }
    .biaoshi6 {
      top: 19vh;
      right: 23vw;
    }
    .caouo4 {
      top: 4vh;
      right: 21vw;
    }
    .wenzi18 {
      top: 6vh;
      left: 3.5vw;
    }
    .wenzi19 {
      top: 11.5vh;
      left: 3.5vw;
    }
    .wenzi20 {
      top: 3.5vh;
      left: 18.5vw;
    }
    .wenzi21 {
      top: 12vh;
      left: 21vw;
    }
    .wenzi22 {
      top: 2vh;
      left: 37.5vw;
    }
    .wenzi23 {
      top: 2vh;
      left: 42.2vw;
    }
    .wenzi24 {
      top: 6.5vh;
      left: 37.5vw;
    }
    .wenzi25 {
      top: 6.5vh;
      left: 42.2vw;
    }
    .wenzi26 {
      top: 12.5vh;
      left: 40vw;
    }
    .wenzi27 {
      top: 18vh;
      left: 35vw;
    }
    .wenzi28 {
      bottom: 3vh;
      left: 43vw;
    }
    .wenzi29 {
      bottom: 5vh;
      left: 29vw;
    }
    .wenzi30 {
      bottom: 12vh;
      left: 1.5vw;
    }
    .wenzi31 {
      bottom: 27.5vh;
      left: 1.5vw;
    }
    .wenzi32 {
      color: #458ca4;
      top: 2.5vh;
      left: 3vw;
    }
    .wenzi33 {
      color: #458ca4;
      top: 18.5vh;
      left: 3vw;
    }
    .wenzi34 {
      bottom: 9.7vh;
      right: -4.5vw;
    }
    .shuzi25 {
      top: 8.5vh;
      left: 3.5vw;
    }
    .shuzi26 {
      top: 13.5vh;
      left: 3.5vw;
    }
    .shuzi27 {
      top: 5.5vh;
      left: 18.5vw;
    }
    .shuzi28 {
      top: 14vh;
      left: 21vw;
    }
    .shuzi29 {
      top: 4vh;
      left: 37.5vw;
    }
    .shuzi30 {
      top: 4vh;
      left: 42.2vw;
    }
    .shuzi31 {
      top: 8.5vh;
      left: 37.5vw;
    }
    .shuzi32 {
      top: 8.5vh;
      left: 42.2vw;
    }
    .shuzi33 {
      top: 14.5vh;
      left: 40.2vw;
    }
    .shuzi34 {
      top: 21vh;
      left: 35vw;
    }
    .shuzi35 {
      bottom: 1vh;
      right: -1.2vw;
    }
    .shuzi36 {
      bottom: 2.5vh;
      left: 29vw;
    }
    .shuzi37 {
      top: 12vh;
      left: 16.1vw;
    }
    .shuzi38 {
      bottom: 14vh;
      left: 27vw;
    }
    .shuzi39 {
      bottom: 10.5vh;
      left: 35vw;
    }
    .shuzi40 {
      bottom: 6vh;
      left: 16vw;
    }
    .shuzi41 {
      top: 2.7vh;
      left: 10.5vw;
    }
    .shuzi42 {
      top: 20.8vh;
      left: 7vw;
    }
    .biaoshi7 {
      bottom: 4vh;
      left: 0.5vw;
    }
    .biaoshi8 {
      bottom: 7vh;
      left: 9.2vw;
    }
    .biaoshi9 {
      bottom: 7vh;
      left: 23.5vw;
    }
    .biaoshi10 {
      bottom: 11vh;
      right: -2.5vw;
    }
    .caouo5 {
      top: 6vh;
      right: 10vw;
    }
    .caouo6 {
      bottom: 18vh;
      left: 23vw;
    }
    .caouo7 {
      bottom: 15vh;
      left: 20vw;
    }
    .caouo8 {
      bottom: 8vh;
      left: 20vw;
    }
    .wenzi35 {
      top: 2vh;
      left: 2vw;
    }
    .wenzi36 {
      top: 2vh;
      left: 8vw;
    }
    .wenzi37 {
      top: 2vh;
      left: 13vw;
    }
    .wenzi38 {
      top: 2vh;
      left: 18vw;
    }
    .wenzi39 {
      top: 6.5vh;
      left: 8vw;
    }
    .wenzi40 {
      top: 6.5vh;
      left: 13vw;
    }
    .wenzi41 {
      top: 12.5vh;
      left: 9.5vw;
    }
    .wenzi42 {
      top: 12.5vh;
      left: 14.5vw;
    }
    .wenzi43 {
      top: 12.5vh;
      left: 5vw;
    }
    .wenzi44 {
      top: 20.5vh;
      left: 10.8vw;
    }
    .wenzi45 {
      top: 5vh;
      left: 21.5vw;
    }
    .wenzi46 {
      top: 5vh;
      left: 27vw;
    }
    .wenzi47 {
      top: 3.2vh;
      left: 36vw;
    }
    .wenzi61 {
      top: -4.5vh;
      right: 6.5vw;
    }
    .wenzi48 {
      top: 17.5vh;
      left: 31.5vw;
    }
    .wenzi48 {
      top: 17.5vh;
      left: 31.5vw;
    }
    .wenzi49 {
      top: 15vh;
      left: 37.5vw;
    }
    .wenzi50 {
      bottom: 15vh;
      left: 23vw;
    }
    .wenzi51 {
      bottom: 8.5vh;
      left: 23vw;
    }
    .wenzi52 {
      bottom: 2.5vh;
      left: 23vw;
    }
    .wenzi53 {
      bottom: 15vh;
      left: 29vw;
    }
    .wenzi54 {
      bottom: 8.5vh;
      left: 29vw;
    }
    .wenzi55 {
      bottom: 15vh;
      left: 39vw;
    }
    .wenzi56 {
      bottom: 10vh;
      left: 39vw;
    }
    .wenzi57 {
      bottom: 5vh;
      left: 39vw;
    }
    .wenzi58 {
      top: 2vh;
      left: 24vw;
    }
    .wenzi59 {
      color: #458ca4;
      bottom: 10.5vh;
      left: 23vw;
    }
    .wenzi60 {
      top: 12.8vh;
      right: 0.5vw;
    }
    .shuzi43 {
      top: 4vh;
      left: 2vw;
    }
    .shuzi44 {
      top: 4vh;
      left: 8vw;
    }
    .shuzi45 {
      top: 4vh;
      left: 13vw;
    }
    .shuzi46 {
      top: 4vh;
      left: 18vw;
    }
    .shuzi47 {
      top: 8.5vh;
      left: 8vw;
    }
    .shuzi48 {
      top: 8.5vh;
      left: 13vw;
    }
    .shuzi49 {
      top: 14.5vh;
      left: 9.5vw;
    }
    .shuzi50 {
      top: 14.5vh;
      left: 14.5vw;
    }
    .shuzi51 {
      top: 14.5vh;
      left: 5vw;
    }
    .shuzi52 {
      top: 22.5vh;
      left: 10.8vw;
    }
    .shuzi53 {
      top: 7.5vh;
      left: 21.5vw;
    }
    .shuzi54 {
      top: 7.5vh;
      left: 27vw;
    }
    .shuzi55 {
      top: 5vh;
      left: 36vw;
    }
    .shuzi69 {
      top: -2vh;
      left: 36vw;
    }
    .shuzi56 {
      top: 19.5vh;
      left: 31.5vw;
    }
    .shuzi57 {
      top: 17vh;
      left: 37.5vw;
    }
    .shuzi58 {
      bottom: 13vh;
      left: 23vw;
    }
    .shuzi59 {
      bottom: 6.5vh;
      left: 23vw;
    }
    .shuzi60 {
      bottom: 0.5vh;
      left: 23vw;
    }
    .shuzi61 {
      bottom: 13vh;
      left: 29vw;
    }
    .shuzi62 {
      bottom: 6.5vh;
      left: 29vw;
    }
    .shuzi63 {
      bottom: 13vh;
      left: 39vw;
    }
    .shuzi64 {
      bottom: 8vh;
      left: 39vw;
    }
    .shuzi65 {
      bottom: 3vh;
      left: 39vw;
    }
    .shuzi66 {
      top: 11.5vh;
      left: 25vw;
    }
    .shuzi67 {
      bottom: 10.5vh;
      left: 30.5vw;
    }
    .shuzi68 {
      top: 12.5vh;
      left: 1vw;
    }
    .biaoshi11 {
      top: 7vh;
      left: 5.2vw;
    }
    .biaoshi12 {
      top: 7vh;
      left: 7.3vw;
    }
    .biaoshi13 {
      top: -0.5vh;
      right: 2.5vw;
    }
    .biaoshi14 {
      top: 4vh;
      right: 2.5vw;
    }
    .biaoshi15 {
      top: 1vh;
      right: 0vw;
    }
    .biaoshi16 {
      bottom: 6vh;
      right: 10.5vw;
    }
    .biaoshi17 {
      bottom: 4vh;
      right: 8vw;
    }
    .biaoshi18 {
      bottom: 5.5vh;
      right: 26vw;
    }
    .caouo9 {
      top: 14.5vh;
      left: 25.5vw;
    }
    .caouo10 {
      top: 15vh;
      left: 1.5vw;
    }
    .caouo11 {
      top: 0vh;
      right: 10vw;
    }
    .caouo12 {
      bottom: 12vh;
      left: 20vw;
    }
  }
}
</style>